var sliderSingle = tns({
    "container": ".smb-carousel",
    "controlsContainer": ".smb-carousel-controls",
    "controlsPosition": "bottom",
    "controls": false,
    "navPosition": "bottom",
    "mouseDrag": true,
    "speed": 400,
    "fixedWidth": 344,
    "center": true,
    "responsive": {
        "768": {
            "fixedWidth": 669
        },
        "992": {
            "controls": true,
            "fixedWidth":669
        },
        "1280": {
            "fixedWidth": 864
        },
        "1366": {
            "fixedWidth": 922
        },
        "1600": {
            "fixedWidth": 1080
        }
    }
});